import React from "react";
import "./footer.css";
const Footer = () => {
  return (
    <div className="footer_container">
      <p>All Right Reserved &copy; MERINASOFT Ltd.</p>
    </div>
  );
};

export default Footer;
